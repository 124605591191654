/* c8 ignore file */
import { defineStore } from 'pinia';
import { USER_ROLE } from '@/const';
import { whoami, favorites } from '@/interfaces/api';
import http from '@/http';

type User = NonNullable<whoami.GET.res.body['user']>;

export const useUserStore = defineStore('user', {
  state: () => ({
    clustersGuest: [] as NonNullable<User['clustersGuest']>,
    firstName: '',
    geoZoneList: [] as NonNullable<User['geoZoneList']>,
    id: '',
    lastName: '',
    mail: '',
    projectsGuest: [] as NonNullable<User['projectsGuest']>,
    realRoles: [] as USER_ROLE[],
    roles: [] as USER_ROLE[],
    type: '',
    ugList: [] as NonNullable<User['ugList']>,
    token: null as null | string,
    notificationToken: null as null | string,
    runningProjectsExpert: [] as NonNullable<User['runningProjectsExpert']>,
    nbUnreadNotifications: 0,
    dashboardManagementUnit: null as null | favorites.POST.res.body,
    dashboardLayout: null as null | favorites.POST.res.body
  }),
  getters: {
    paramsManagementUnitCounter: (state: { dashboardManagementUnit: null | favorites.POST.res.body }) => {
      return {
        managementUnits:
          state.dashboardManagementUnit?.data.ug && state.dashboardManagementUnit?.data.ug !== 'all' ? [state.dashboardManagementUnit?.data.ug] : undefined,
        managementUnitExtended: !!state.dashboardManagementUnit?.data.extend
      };
    }
  },
  actions: {
    refreshUnreadNotificationsCounter: async function() {
      try {
        const res = await http({
          url: `${import.meta.env.VITE_API_URL}/notifications?page=1&count=1&unread=true`,
          method: 'GET'
        });
        this.nbUnreadNotifications = res.data.total;
      } catch (err) {
        console.error(err);
      }
    }
  }
});

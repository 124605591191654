/* c8 ignore start */
import { LS, USER_ROLE } from '@/const';
import { IGeoZone } from '@/interfaces/geo-zone';
import { IProject } from '@/interfaces/project';
import { IManagementUnit } from '@/interfaces/ug';
import { ICluster } from '@/interfaces/cluster';
import { useUserStore } from '@/stores/user';

export interface IUser {
  id: string;
  firstName: string;
  lastName: string;
  mail: string;
  type: string;
  roles: string[];
  realRoles: string[];
  ugList: IManagementUnit[];
  geoZoneList: IGeoZone[];
  projectsGuest: IProject[];
  clustersGuest: ICluster[];
  runningProjectsExpert: IProject[];
}
function hasPermittedRoles(user: IUser, { permittedRoles, isRealRole }: { permittedRoles: string[]; isRealRole: boolean }): boolean {
  if (permittedRoles.length === 0) {
    return true;
  }

  if (user?.roles === undefined) {
    return false;
  }

  const userRoles = isRealRole ? user.realRoles : user.roles;
  return userRoles.some((role) => permittedRoles.includes(role));
}

/*
# Consultation projet
╔══════════════════╦══════════════════╦══════════════════════════════════════════════════════╗
║      Profils     ║ Non confidentiel ║                      Confidentiel                    ║
║                  ║                  ╠═══════════════════════════╦════╦═════════════════════╣
║                  ║                  ║ Assigné expert/rapporteur ║ UG ║       Pas UG        ║
║                  ║                  ║                           ║    ╠════════════╦════════╣
║                  ║                  ║                           ║    ║ Pas invité ║ Invité ║
╠══════════════════╬══════════════════╬═══════════════════════════╬════╬════════════╬════════╣
║ Non connecté     ║                  ║                           ║    ║            ║        ║
╠══════════════════╬══════════════════╬═══════════════════════════╬════╬════════════╬════════╣
║ Agent Comptable  ║                  ║                           ║    ║            ║        ║
╠══════════════════╬══════════════════╬═══════════════════════════╬════╬════════════╬════════╣
║ Consultation     ║ #                ║ #                         ║    ║            ║        ║
╠══════════════════╬══════════════════╬═══════════════════════════╬════╬════════════╬════════╣
║ Auditeur externe ║ #                ║ #                         ║ #  ║ #          ║ #      ║
╠══════════════════╬══════════════════╬═══════════════════════════╬════╬════════════╬════════╣
║ Commun ADEME     ║ #                ║ #                         ║ #  ║            ║ #      ║
╠══════════════════╬══════════════════╬═══════════════════════════╬════╬════════════╬════════╣
║ CDP              ║ #                ║ #                         ║ #  ║ #          ║ #      ║
╠══════════════════╬══════════════════╬═══════════════════════════╬════╬════════════╬════════╣
║ Admin            ║ #                ║ #                         ║ #  ║ #          ║ #      ║
╚══════════════════╩══════════════════╩═══════════════════════════╬════╩════════════╩════════╝
*/
function canViewProject(user: IUser, project: IProject) {
  if (user.roles.includes(USER_ROLE.__CONNECTED__) === false) {
    return false;
  }
  if (user.roles.includes(USER_ROLE.OPALE_ADMINISTRATOR)) {
    return true;
  }
  if (user.roles.includes(USER_ROLE.ACCOUNTING_OFFICER)) {
    return true;
  }
  if (user.roles.includes(USER_ROLE.CDP_MANAGER)) {
    return true;
  }
  // Projet CONFIDENTIEL
  if (project.businessSecrecyRisk) {
    // Si l'utilisateur est EXPERT ou RAPPORTEUR sur le projet (avec le rôle correspondant)
    if (user.runningProjectsExpert?.find((pj) => pj.id === project.id) && user.roles.includes(USER_ROLE.EXPERT)) {
      // Autorisé à y accéder
      return true;
    }

    // Si l'utilisateur fait partie de l'UG du projet
    if (user.ugList.find((ug) => ug.id === project.managementUnit.id)) {
      // Droit d'accès à condition d'avoir les rôles AUDITEUR_EXTERNE ou COMMUN minimum
      return user.roles.includes(USER_ROLE.EXTERNAL_AUDITOR) || user.roles.includes(USER_ROLE.ADEME_COMMON);
      // Si l'utilisateur ne fait PAS partie de l'UG du projet
      // Si l'utilisateur est INVITÉ dans le projet
      // -> Soit parce qu'il à un rôle interne (suivi ADEME) sur le projet
      // -> Soit parce qu'il à un rôle interne sur un des groupements techniques du projet
    } else if (
      user.projectsGuest.find((pj) => pj.id === project.id)
      || user.clustersGuest.some((c) => c.nature === 'T' && c.projects?.some((p) => p.id === project.id))
    ) {
      // Droit d'accès à condition d'avoir les rôles AUDITEUR_EXTERNE ou COMMUN minimum
      return user.roles.includes(USER_ROLE.EXTERNAL_AUDITOR) || user.roles.includes(USER_ROLE.ADEME_COMMON);
      // Utilisateur PAS INVITÉ dans le projet
    } else {
      // Droit d'accès uniquement si AUDITEUR_EXTERNE
      return user.roles.includes(USER_ROLE.EXTERNAL_AUDITOR);
    }
    // Projet PAS CONFIDENTIEL
  } else {
    // Autorisé à y accéder si rôle CONSULTATION
    return user.roles.includes(USER_ROLE.CONSULTATION);
  }
}
/*
# Modification projet
╔══════════════════╦══════════════════════════╦══════════════════════════╗
║      Profils     ║     Non confidentiel     ║       Confidentiel       ║
║                  ╠════╦═════════════════════╬════╦═════════════════════╣
║                  ║ UG ║       Pas UG        ║ UG ║       Pas UG        ║
║                  ║    ╠════════════╦════════╣    ╠════════════╦════════╣
║                  ║    ║ Pas invité ║ Invité ║    ║ Pas invité ║ Invité ║
╠══════════════════╬════╬════════════╬════════╬════╬════════════╬════════╣
║ Non connecté     ║    ║            ║        ║    ║            ║        ║
╠══════════════════╬════╬════════════╬════════╬════╬════════════╬════════╣
║ Agent Comptable  ║    ║            ║        ║    ║            ║        ║
╠══════════════════╬════╬════════════╬════════╬════╬════════════╬════════╣
║ Consultation     ║    ║            ║        ║    ║            ║        ║
╠══════════════════╬════╬════════════╬════════╬════╬════════════╬════════╣
║ Auditeur externe ║    ║            ║        ║    ║            ║        ║
╠══════════════════╬════╬════════════╬════════╬════╬════════════╬════════╣
║ Commun ADEME     ║ #  ║            ║ #      ║ #  ║            ║ #      ║
╠══════════════════╬════╬════════════╬════════╬════╬════════════╬════════╣
║ CDP              ║ #  ║ #          ║ #      ║ #  ║ #          ║ #      ║
╠══════════════════╬════╬════════════╬════════╬════╬════════════╬════════╣
║ Admin            ║ #  ║ #          ║ #      ║ #  ║ #          ║ #      ║
╚══════════════════╩════╩════════════╩════════╩════╩════════════╩════════╝
*/
function canEditProject(user: IUser, project: IProject) {
  if (user.roles.includes(USER_ROLE.__CONNECTED__) === false) {
    return false;
  }
  if (user.roles.includes(USER_ROLE.OPALE_ADMINISTRATOR)) {
    return true;
  }
  if (user.roles.includes(USER_ROLE.CDP_MANAGER)) {
    return true;
  }
  // confidentiel, pas confidentiel, même chose
  if (
    // Si l'utilisateur fait partie de l'UG
    user.ugList.find((ug) => ug.id === project.managementUnit.id)
    // Si l'utilisateur ne fait PAS partie de l'UG du projet
    // Si l'utilisateur est INVITÉ dans le projet
    // -> Soit parce qu'il à un rôle interne (suivi ADEME) sur le projet
    // -> Soit parce qu'il à un rôle interne sur un des groupements techniques du projet
    || user.projectsGuest.find((pj) => pj.id === project.id)
    || user.clustersGuest.some((c) => c.nature === 'T' && c.projects?.some((p) => p.id === project.id))
  ) {
    // Droits d'édition à condition d'avoir au moins le rôle commun
    return user.roles.includes(USER_ROLE.ADEME_COMMON);
  }
  // Sinon, il ne fait pas partie de l'UG et n'est pas invité, aucun droit d'édition
  return false;
}

/*
Consultation/modification tiers
╔══════════════════╦══════════════╦══════════════╗
║      Profils     ║ Consultation ║ Modification ║
╠══════════════════╬══════════════╬══════════════╣
║ Non connecté     ║              ║              ║
╠══════════════════╬══════════════╬══════════════╣
║ Agent Comptable  ║              ║              ║
╠══════════════════╬══════════════╬══════════════╣
║ Consultation     ║ #            ║              ║
╠══════════════════╬══════════════╬══════════════╣
║ Auditeur externe ║ #            ║              ║
╠══════════════════╬══════════════╬══════════════╣
║ Commun ADEME     ║ #            ║ #            ║
╠══════════════════╬══════════════╬══════════════╣
║ CDP              ║ #            ║ #            ║
╠══════════════════╬══════════════╬══════════════╣
║ Admin            ║ #            ║ #            ║
╚══════════════════╩══════════════╩══════════════╝
*/
function canViewThirdParty(user: IUser) {
  return (
    user.roles.includes(USER_ROLE.__CONNECTED__)
    || user.roles.includes(USER_ROLE.CONSULTATION)
    || user.roles.includes(USER_ROLE.EXTERNAL_AUDITOR)
    || user.roles.includes(USER_ROLE.ADEME_COMMON)
    || user.roles.includes(USER_ROLE.CDP_MANAGER)
    || user.roles.includes(USER_ROLE.OPALE_ADMINISTRATOR)
  );
}
function canEditThirdParty(user: IUser) {
  if (user.roles.includes(USER_ROLE.__CONNECTED__) === false) {
    return false;
  }
  return user.roles.includes(USER_ROLE.ADEME_COMMON) || user.roles.includes(USER_ROLE.CDP_MANAGER) || user.roles.includes(USER_ROLE.OPALE_ADMINISTRATOR);
}

/*
cluster
*/
function canEditCluster(user: IUser, cluster: ICluster) {
  if (user.roles.includes(USER_ROLE.__CONNECTED__) === false) {
    return false;
  }
  if (user.roles.includes(USER_ROLE.OPALE_ADMINISTRATOR)) {
    return true;
  }
  // confidentiel, pas confidentiel, même chose
  if (
    user.ugList.find((ug) => {
      return ug.id === cluster.managementUnit.id;
    })
  ) {
    // UG
    return user.roles.includes(USER_ROLE.ADEME_COMMON);
    // pas UG
  } else if (
    user.clustersGuest.find((cl) => {
      return cl.id === cluster.id;
    })
  ) {
    // invité
    return user.roles.includes(USER_ROLE.ADEME_COMMON);
  } else {
    // pas invité
    return false;
  }
}

/*
others
*/

function isPureAccountingOfficer(user: IUser) {
  if (!user) return false;
  const roles = user.roles.filter((role) => {
    return role !== USER_ROLE.USER && role !== USER_ROLE.__CONNECTED__ && role !== USER_ROLE.EXPERT;
  });
  if (roles.length === 1 && roles[0] === USER_ROLE.ACCOUNTING_OFFICER) {
    return true;
  }
  return false;
}

function isAccountingView(user: IUser) {
  if (!user) return false;
  return isPureAccountingOfficer(user) || user.roles.includes(USER_ROLE.__FAKE_ACCOUNTING__);
}

function clearUser() {
  const userStore = useUserStore();
  for (const prop in LS) {
    localStorage.removeItem((LS as Record<string, any>)[prop]);
  }
  userStore.$reset();
}

export const hUser = {
  hasPermittedRoles,
  clearUser,
  canViewProject,
  canEditProject,
  canViewThirdParty,
  canEditThirdParty,
  canEditCluster,
  isPureAccountingOfficer,
  isAccountingView
};
/* c8 ignore stop */

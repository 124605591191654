/* c8 ignore file */
import EventBus from '@/event-bus';
import { HTTP_EVENTS, REFERENTIALS } from '@/const';
import router from '@/router';
import { useUserStore } from '@/stores/user';
import qs from 'qs';
import isNil from 'lodash.isnil';

type RequestData = Omit<RequestInit, 'body'> & {
  url: string;
  method: (typeof REFERENTIALS.HTTP_METHODS)[number];
  body?: string | any[] | Record<string, any>;
  params?: Record<string, unknown>;
  headers?: Record<string, string>;
  responseType?: 'blob' | 'arrayBuffer' | 'formData' | 'text' | 'json';
};

function requestInterceptor(options: RequestData) {
  // Init default headers + convert method to uppercase
  const opt = {
    headers: { 'Content-Type': 'application/json' },
    priority: 'low',
    ...options,
    method: options.method.toUpperCase() as RequestData['method']
  };
  // Unless we are using a FormData as body (in wich case, fetch auto-set content-type)
  if (options.body instanceof FormData && opt.headers) {
    delete opt.headers['Content-Type'];
  } else if (opt.body) {
    // Body must be passed as a String
    opt.body = JSON.stringify(opt.body);
  }
  // Params must be inserted in the url (mostly GET/DELETE cases)
  if (opt.params && Object.keys(opt.params).length !== 0) {
    // Remove null or undefined params entries
    const paramsEntries = Object.entries(opt.params).filter((entry) => {
      return !isNil(entry[1]);
    });
    // Add params to url
    opt.url = `${opt.url}?${qs.stringify(Object.fromEntries(paramsEntries), { arrayFormat: 'brackets' })}`;
    // Delete now useless property
    delete opt.params;
  }
  // Add possible token session
  const userStore = useUserStore();
  if (userStore.token) {
    opt.headers.Authorization = `Bearer ${userStore.token}`;
  }
  return opt;
}

async function responseSuccessInterceptor(response: Response, method: (typeof REFERENTIALS.HTTP_METHODS)[number], responseType: RequestData['responseType']) {
  // Convert response data to correct type
  const res = Object.assign(response, { data: {} as any });
  if (res.status !== 204) {
    try {
      switch (responseType) {
        case 'blob':
          res.data = await res.blob();
          break;
        case 'arrayBuffer':
          res.data = await res.arrayBuffer();
          break;
        case 'formData':
          res.data = await res.formData();
          break;
        case 'text':
          res.data = await res.text();
          break;
        default:
          res.data = await res.text();
          res.data = res.data ? JSON.parse(res.data) : {};
          break;
      }
    } catch (err) {
      console.error({ res, err });
    }
  }
  if (!res.ok) throw { response: res };
  if (['POST', 'DELETE', 'PATCH', 'PUT'].includes(method) && res?.url?.includes('bo/auth') === false) {
    EventBus.$emit(HTTP_EVENTS.METHOD_SUCCESS);
  }
  return res;
}

async function responseErrorInterceptor(error: any, options: RequestData) {
  if (error && options.signal) error.isCancel = options.signal.aborted;
  if (!error?.response?.status) {
    throw error;
  }
  const res = error.response as Response;
  switch (res.status) {
    case 401:
      console.warn('HTTP Interceptor ⛔ 401, logout');
      router.replace({ name: 'logout' }).catch(() => {
        /* logout */
      });
      break;
    case 403:
      console.warn('HTTP Interceptor ⛔ 403, no-access');
      router.replace({ name: 'no-access' }).catch(() => {
        /* no-access */
      });
      break;
  }
  return error;
}

async function http(options: RequestData) {
  try {
    const opt = requestInterceptor(options);
    // Use fetch to execute action
    const response = await fetch(opt.url, opt as RequestInit);
    return await responseSuccessInterceptor(response, opt.method, options.responseType);
  } catch (error) {
    throw await responseErrorInterceptor(error, options);
  }
}

export default http;
